import React from "react";
import { NavLink } from "react-router-dom";
import logo from "../../assets/images/Logo.svg";

const Header = () => {
  return (
    <header className="relative">
      <div className="flex justify-between text-center items-center md:h-[108px] h-[70px] text-[14px] font-medium">
        <NavLink to="/">
          <img src={logo} alt="logo of the RapidoGate" className="logo" />
        </NavLink>
        {/* header mobile */}
        <div className="pointer-cursor lg:hidden block">
          <label for="menu-toggle">
            <svg
              className="fill-current text-gray-900"
              xmlns="http://www.w3.org/2000/svg"
              width="20"
              height="20"
              viewBox="0 0 20 20"
            >
              <title>menu</title>
              <path d="M0 3h20v2H0V3zm0 6h20v2H0V9zm0 6h20v2H0v-2z"></path>
            </svg>
          </label>
          <input className="hidden" type="checkbox" id="menu-toggle" />

          <div
            className=" lg:flex absolute w-full top-12 z-50 left-0 bg-[#05e0d5]"
            id="menu"
          >
            <nav>
              <ul className="text-base text-theme_color lg:pt-0">
                <li>
                  <NavLink
                    className="lg:p-4 py-3 px-0 block border-b-2  border-indigo-400"
                    to="/"
                  >
                    Home
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    className="lg:p-4 py-3 px-0 block border-b-2  border-indigo-400"
                    to="/about"
                  >
                About Company
                  </NavLink>
                </li>
                
             
                <li>
                  <NavLink
                    to="/contactus"
                    className="lg:p-4 py-3 px-0 block border-b-2  border-indigo-400"
                  >
                    Contact Us
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/faqs"
                    className="lg:p-4 py-3 px-0 block border-b-2  border-indigo-400"
                  >
                    FAQs
                  </NavLink>
                </li>
                <li>
                  <NavLink
                    to="/login"
                    className="lg:p-4 py-3 px-0 block border-b-2  "
                  >
                    Sign In
                  </NavLink>
                </li>
              </ul>
            </nav>
          </div>
        </div>
          {/* header mobile */}
        <div className="lg:flex hidden gap-8 justify-center text-center items-center navitems">
          <NavLink to="/">Home</NavLink>
          <NavLink to="/about">About Company</NavLink>
          <NavLink to="/contactus">Contact Us</NavLink>
          <NavLink to="/faqs">FAQs</NavLink>
      
        </div>
        <div className="lg:flex hidden gap-10 justify-end text-center items-center">
          <NavLink to="/login">Sign In</NavLink>
          <NavLink to="/signup">
            <button className="w-[137px] h-[48px] bg-theme_color text-white rounded-[10px] ">
              Start Free
            </button>
          </NavLink>
        </div>
      </div>
    </header>
  );
};

export default Header;
