import React from "react";
import { useNavigate } from "react-router-dom";
import sideleft from "../../assets/images/side-left.png";
import { Link } from "react-router-dom";
import { AiOutlineLeft, AiOutlineMail } from "react-icons/ai";
import { BsInfoLg } from "react-icons/bs";
import { FiLock } from "react-icons/fi";
import { useFormik } from "formik";
import * as Yup from "yup";
const date = new Date();

const Login = () => {
  const navigate = useNavigate();
  const validationSchema = Yup.object().shape({
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    password: Yup.string().required("Password is required").min(8),
  });
  const formik = useFormik({
    initialValues: {
      email: "",
      password: "",
    },
    validationSchema: validationSchema,
    onSubmit: (values) => {
      // Your login logic here
      console.log("Login form submitted with values:", values);
      navigate("/welcome");
    },
  });
  return (
    <div className="flex  w-screen h-screen  flex-wrap">
      <div className=" lg:w-1/2 w-full lg:h-full lg:flex hidden">
        <img src={sideleft} alt="login page image of rapidogate" className="w-full h-full object-cover" />
      </div>
      <div className=" lg:w-1/2 w-full  lg:pr-16 py-4 px-3">
        <div className=" md:w-[90%] w-[99%]">
          <div className="flex justify-between">
            <Link
              to="/"
              className="text-[#757575] font-Popins text-[12px] font-normal flex items-center text-center"
            >
              <AiOutlineLeft className="lg:text-[22px] mr-1" /> Return Home
            </Link>
            <p className="text-[#757575] font-Popins text-[12px] font-normal ">
              Not a member yet?{" "}
              <Link
                to="/signup"
                className="text-[#201064] font-bold underline "
              >
                JOIN NOW
              </Link>
            </p>
          </div>
        </div>

        <div
          className="flex flex-col w-full   md:h-[90vh] h-[88vh] lg:gap-6 gap-4 justify-center text-center
        items-center"
        >
          <h1 className="text-[#201064] lg:text-[25px] text-[20px] font-extrabold ">
            Great to See You Again!
          </h1>
          <p className="text-[#424242] uppercase font-normal lg:text-[16px] text-[14px] ">
            LOG IN TO CONTINUE
          </p>
          <div className="lg:w-[70%] w-full my-6 flex flex-col lg:gap-14 gap-10">
            <div>
            <div className="relative lg:h-[32px] h-[26px]">
              <input
                type="email"
                placeholder="Email"
                name="email"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.email}
                className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pl-10 h-full pb-1 ${
                  formik.touched.email && formik.errors.email
                    ? "border-red-500"
                    : ""
                }`}
              />
           

              <span className="absolute left-0">
                <AiOutlineMail className="text-[24px] text-[#8D8D8D]" />{" "}
              </span>
            </div>
            {formik.touched.email && formik.errors.email && (
                <span className="text-red-500 text-[12px]">
                  {formik.errors.email}
                </span>
              )}
              </div>
              <div>
            <div className="relative lg:h-[32px] h-[26px]">
              <input
                type="password"
                placeholder="Password"
                name="password"
                onChange={formik.handleChange}
                onBlur={formik.handleBlur}
                value={formik.values.password}
                className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pl-10 h-full pb-1 ${
                  formik.touched.password && formik.errors.password
                    ? "border-red-500"
                    : ""
                }`}
              />
           
              <span className="absolute left-0">
                <FiLock className="text-[24px] text-[#8D8D8D]" />{" "}
              </span>
            </div>
            <span className="text-red-500 text-[12px] ">
            {formik.touched.password && formik.errors.password && 
               
                 <> {formik.errors.password}</>
                
              }
              </span>
              </div>
            <button
              type="submit"
              className="bg-[#201064] lg:text-[16px] text-[13px] lg:h-[58px] h-[50px] text-white rounded-[5px] font-medium"
              onClick={formik.handleSubmit}
            >
              Login
            </button>
          </div>
          <p className="text-[#757575] font-Popins text-[12.8px] font-normal flex items-center text-center">
            Having Password Issues?
          </p>
        </div>
        <div className=" md:w-[90%] w-[99%] ">
          <div className="flex justify-between">
            <p className="text-[#757575] font-Popins text-[12.8px] font-normal flex items-center text-center">
              Copyright {date.getFullYear()} Rapidogate. All rights Reserved
            </p>
            <p className="text-[#757575] font-Popins text-[12px] font-normal  flex items-center text-center">
              <BsInfoLg className="text-[22px] mr-1" />{" "}
              <Link to="/faqs">Need help?</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Login;
