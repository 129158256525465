import React from "react";
import BG from "../../assets/images/background.svg";
import product from "../../assets/images/product.png";
import transaction from "../../assets/images/transaction.svg";
import support from "../../assets/images/support.svg";
import secure from "../../assets/images/secure.svg";
import integration from "../../assets/images/integration.svg";
import { useNavigate } from "react-router-dom";
const Business = () => {
  const containerStyle = {
    width: "100vw",
    minHeight: window.innerWidth < 1400 ? "95.5vw" : "71vw", // Change this to "minHeight"

    backgroundImage: `url(${BG})`,
    backgroundSize: "100% 100%", // Set backgroundSize to "100% auto"
    backgroundRepeat: "no-repeat",
  };
  const navigate = useNavigate();

  return (
    <>
      <div
        className={`relative lg:mt-[14rem] mt-[7rem] curve_bg  xl:my-10 pb-4 `}
        style={window.innerWidth > 1250 ? containerStyle : {}}
      >
        <div className="flex flex-col text-center w-full items-center h-full relative">
          <div className="mt-[-9rem] mx-4 sm:mx-0">
            <div
              className="xl:w-[50vw] md:w-[65vw]   xl:h-[390px] h-[270px]    rounded-lg  md:mx-0 "
              style={{
                backgroundImage: `url(${product})`,
                backgroundRepeat: "no-repeat",
                backgroundSize: "cover",
                backgroundPosition: "center",
              }}
            >
              <div className="bg-opacity-50 bg-blue-900 rounded-lg w-full h-full bg-cover bg-center bg-no-repeat text-white flex flex-col justify-center text-center items-center gap-4 md:px-1 ">
                <h2 className="font-extrabold xl:text-[40px] md:text-[28px] text-[22px] tracking-tight">
                  Get full payments with each scan
                </h2>
                <p className="font-medium xl:text-[20px] md:text-[15px] text-[10px]  tracking-tight px-5">
                  Be among the 200+ businesses enjoying seamless growth and{" "}
                  <br className="hidden lg:block" />
                  simplified transactions with Rapidogate's trusted Dynamic QR
                  code <br className="hidden lg:block" /> service.
                </p>
                <button
                  className="px-[2.7em] lg:text-[16px] md:text-[13px] text-[11px] py-[0.6em] bg-[#05E0D5] rounded-full font-semibold text-black"
                  onClick={() => navigate("/login")}
                >
                  Get Now
                </button>
              </div>
            </div>
          </div>

          <div className="mt-[4rem]  w-full">
            <div>
              <h1 className="xl:text-[42px] md:text-[30px] text-[24px] tracking-tighter font-extrabold">
                Boost Your Revenue with Smart Payment Solutions
              </h1>
              <p className="text-[#64607D] xl:text-[20px]  md:text-[14px] text-[13px]  font-medium">
                Integrate Rapidogate and  join the ranks of 200+ businesses{" "}
                <br className="hidden md:block" />
                enjoying effortless growth and streamlined profits.
              </p>
            </div>
            <div className="containar w-full my-4 mt-12 xl:shadow-none shadow-md">
              <div className="rounded-[30px] bg-white  w-full flex xl:p-10 p-4 text-left md:flex-row flex-col">
                <div className="md:w-[35%] w-full ">
                  <span className="text-[#181066] uppercase md:text-[14px] text-[10px] font-bold font-Gilroy  ">
                    RAPIDOGATE’S FEATURES
                  </span>
                  <h3 className="xl:text-[40px] md:text-[30px] text-[24px] font-extrabold tracking-tight md:leading-[48px] xl:pr-32 mb-5 ">
                    See How Dynamic QR Code Service will Help Your Business
                  </h3>
                </div>
                <div className="md:w-[65%] w-full ">
                  <div className="  flex flex-wrap gap-6  justify-center ">
                    <div className=" md:w-[46%] w-full flex flex-row md:flex-col md:gap-0 gap-3 items-start">
                      <img
                        src={transaction}
                        alt="transaction iamge"
                        className=" md:w-[50px]  w-[45px]"
                      />
                      <div>
                        <h4 className="font-bold xl:text-[20px] md:text-[18px] font-Glory md:mt-2 ">
                          Accurate Payment
                        </h4>
                        <p className="text-[#374151] text-[14px] font-normal mt-1 ">
                          Set your price, generate a QR, and offer a
                          straightforward payment path for your customers—no
                          fuss, no errors, just efficiency.
                        </p>
                      </div>
                    </div>
                    <div className=" md:w-[46%] w-full flex flex-row md:flex-col md:gap-0 gap-3 items-start">
                      <img
                        src={support}
                        alt="support image "
                        className=" md:w-[50px]  w-[45px]"
                      />
                      <div>
                        <h4 className="font-bold xl:text-[20px] md:text-[18px] font-Glory md:mt-2 ">
                          Accepts All Major UPI Apps
                        </h4>
                        <p className="text-[#374151] md:text-[14px] text-[13px]  font-normal mt-1 ">
                          Our QR solution accepts payments from a comprehensive
                          list of UPI and banking applications, ensuring
                          versatility for your customers' convenience.
                        </p>
                      </div>
                    </div>
                    <div className=" md:w-[46%] w-full flex flex-row md:flex-col md:gap-0 gap-3 items-start">
                      <img
                        src={secure}
                        alt="secure image of the rapidogate"
                        className=" md:w-[50px]  w-[45px]"
                      />
                      <div>
                        <h4 className="font-bold xl:text-[20px] md:text-[18px]  font-Glory md:mt-2 ">
                          Secure Transactions
                        </h4>
                        <p className="text-[#374151]  md:text-[14px] text-[13px] font-normal mt-1 ">
                          Generate a new QR for each sale, offering a secure and
                          easy payment collection method for your website or
                          in-store system.
                        </p>
                      </div>
                    </div>
                    <div className=" md:w-[46%] w-full flex flex-row md:flex-col md:gap-0 gap-3 items-start">
                      <img
                        src={integration}
                        alt="integration image of the tapidogate"
                        className=" md:w-[50px]  w-[45px]"
                      />
                      <div>
                        <h4 className="font-bold xl:text-[20px] md:text-[18px]  font-Glory md:mt-2 ">
                          Straightforward System Integration
                        </h4>
                        <p className="text-[#374151]  md:text-[14px] text-[13px]  font-normal mt-1 ">
                          Easily integrate our service with simple, code-free
                          setup options, along with developer-friendly APIs and
                          SDKs for a smooth start.
                        </p>
                      </div>
                    </div>
                  </div>
                </div>
              </div>
            </div>
          </div>
        </div>
      </div>
    </>
  );
};

export default Business;
