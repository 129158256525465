import React from "react";
import wave from "../../assets/images/wave.svg";
import glob from "../../assets/images/glob.svg";
import path_line from "../../assets/images/path_line.png";
import line from "../../assets/images/straightline.png"
import { useNavigate } from "react-router-dom";

const Work = () => {
  const navigate = useNavigate()
  return (
    <div
      className="containar mt-16 w-screen overflow-hidden "
      style={{
        backgroundImage: window.innerWidth >720? `url(${glob})`: "none",
        backgroundRepeat: "no-repeat",
        backgroundSize: "cover",
      }}
    >
      <div className="relative w-full xl:mt-32 xl:mb-20 z-30">
        <p className="font-bold text-[#181066] xl:text-[14px] md:text-[12px] text-[10px] tracking-[0] leading-[normal]">
          OUR SEAMLESS PROCESS ACROSS THE WORLD
        </p>
        <p className="  font-extrabold  xl:text-[40px] md:text-[30px] text-[26px] tracking-[-1.20px] md:leading-[48px] lg:my-4 my-2">
          How does RapidoGate <br className="hidden xl:block"/> Dynamic QR work?
        </p>
        <button className="lg:h-[47px] h-[36px] lg:w-[167px] w-full bg-[#181066]  rounded-full font-normal text-white z-40"  onClick={()=>navigate('/login')}>
          {" "}
          Get Started
        </button>

        <div className="relative w-full wave_container   " style={{
          userSelect:"none"
        }}>
          <img
            className="absolute w-full left-[-2%] wave z-[-100]"
            alt="path line of how its work"
            src={path_line}
          />
 <img
            className="absolute  straight "
            alt="straight line of how its work"
            src={line}
          />
          <div className=" one top-[10vw] left-[-1vw] absolute xl:w-[279px]  w-full ">
           
            <div className="relative md:mt-12 mt-10">
              <div className="xl:left-[162px] md:left-[90%] left-[76%] absolute xl:top-0 top-[-75px] z-[-1] font-black md:text-[204px] text-[180px] tracking-[-4.08px] leading-[204px] whitespace-nowrap text-[#F2F2F2]">
                1
              </div>
              <div className=" ">
                <div className=" font-extrabold tracking-[-0.48px] leading-[30px] whitespace-nowrap">
                Product Checkout
                </div>
                <p className="  font-medium text-[#64607d]  tracking-[-0.32px] leading-[30px]">
                  Customer selects the product, heads to checkout; your site
                  prompts Rapidogate for the next
                </p>
              </div>
            </div>
          </div>
          <div className="left-[26vw] absolute xl:w-[279px] second">
            <div className="relative mt-6">
              <div className=" xl:left-[162px] md:left-[90%] left-[70%] absolute top-[-25%] text-[#F2F2F2] z-[-1] font-black md:text-[204px] text-[180px] tracking-[-4.08px] leading-[204px] whitespace-nowrap">
                2
              </div>
              <div className=" ">
                <div className=" font-extrabold tracking-[-0.48px] leading-[30px] whitespace-nowrap">
                QR Code Generation
                </div>
                <p className="  font-medium text-[#64607d]  tracking-[-0.32px] leading-[30px]">
                  At the time of Checkout, rapidogate generates a unique QR code
                  that shows up right away for the shopper to checkout.
                </p>
              </div>
            </div>
          </div>

          <div className=" third absolute xl:w-[279px] ">
            <div className="relative lg:mt-4 mt-5">
              <div className="xl:left-[162px] md:left-[90%] left-[70%] absolute top-[-25%] text-[#F2F2F2] z-[-1] font-black md:text-[204px] text-[180px]  tracking-[-4.08px] leading-[204px] whitespace-nowrap">
                3
              </div>
              <div className=" ">
                <div className=" font-extrabold tracking-[-0.48px] leading-[30px] whitespace-nowrap">
                UPI Payment
                </div>
                <p className="  font-medium text-[#64607d]  tracking-[-0.32px] leading-[30px]">
                  A quick QR scan from the customer's UPI app transfers funds
                  directly to you.
                </p>
              </div>
            </div>
          </div>
          <div className="fourth  absolute xl:w-[279px] ">
            <div className="relative lg:mt-2 mt-3">
              <div className="xl:left-[162px] md:left-[90%] left-[70%] absolute top-[-25%] text-[#F2F2F2] z-[-1] font-black md:text-[204px] text-[180px]  tracking-[-4.08px] leading-[204px] whitespace-nowrap">
                4
              </div>
              <div className="ml-4 ">
                <div className=" font-extrabold tracking-[-0.48px] leading-[30px] whitespace-nowrap">
                Transaction Confirmation
                </div>
                <p className="  font-medium text-[#64607d]  tracking-[-0.32px] leading-[30px]">
                  Rapidogate quickly makes sure the money is sent and tells your
                  system it's done.
                </p>
              </div>
            </div>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Work;
