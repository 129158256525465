import React from "react";

import { useNavigate } from "react-router-dom";
import { heroSvg } from "../../assets/hero";

const Hero = () => {
  const navigate = useNavigate();
  return (
    <>
      <div className=" containar flex md:flex-row flex-col gap-4 lg:py-2 md:mt-0 xl:mt-6 mt-4 relative items-start ">
        <span className="elipse_1"></span>
        <span className="elipse_2 z-[-1000]"></span>
        <div className="md:w-[42%] w-full  z-40 lg:pr-16">
          <h1 className="xl:text-[40px] text-[22px] font-extrabold tracking-tight xl:leading-[50px] leading-7 ">
            Accept UPI payments directly to your account with more flexibility
            and control.
          </h1>
          <p className=" font-semibold md:text-[13px] text-[11px] leading-[16px] text-[#181066]  my-4">
            We support all UPI and bank APPs, including but not{" "}
            <br className="hidden lg:block" />
            limited - GooglePay, PayTm, BHIM, PhonePe, AmazonPay.
          </p>
          <p className=" font-normal md:text-[13px] text-[11px] text-[#333] md:leading-[16px] tracking-[0.2px] my-4">
            Improve your payment process with Rapidogate Dynamic{" "}
            <br className="hidden lg:block" />
            QR codes white label solution
          </p>

          <button
            className="bg-[#181066] lg:text-[16px] text-[13px] rounded-full text-white font-normal xl:py-[20px] md:py-[10px] py-[5px] px-[72px] md:w-auto w-[99%] md:mt-5 z-40"
            onClick={() => navigate("/login")}
          >
            Start Accepting Payments &nbsp;&nbsp; {" > "}
          </button>
        </div>
        <div className="flex-1 z-40">
          <div className="hero_container w-full h-full">{heroSvg}</div>
        </div>{" "}
      </div>
    </>
  );
};

export default Hero;
