import React, { useEffect, useState } from "react";
import emailjs from "emailjs-com";
import sideleft from "../../assets/images/side-left.png";
import { Link, useNavigate } from "react-router-dom";
import { AiOutlineLeft } from "react-icons/ai";
import { BsInfoLg } from "react-icons/bs";
import { useFormik } from "formik";
import * as Yup from "yup";

import { recapcha, reload } from "../../assets/svgs";
import { toast } from "react-toastify";
const date = new Date();

const Signup = () => {
  const [captchaText, setCaptchaText] = useState();
  const [formData, setFormData] = useState({});

  const validationSchema = Yup.object().shape({
    companyName: Yup.string().required("Company Name is required"),
    email: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    websiteURL: Yup.string().required("Website URL is required"),
    phoneNumber: Yup.string().required("Phone Number is required"),
    message: Yup.string().required("Message is required"),
    captcha: Yup.string().required("Captcha is required"),
  });
  const navigate = useNavigate()

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const sendEmail = () => {
    emailjs
      .send(
        "service_446oasn",
        "template_u8sau2h",
        {
          to_name: formData?.companyName,
          email: formData?.email,
          website: formData?.websiteURL,
          phone: formData?.phoneNumber,
          message: formData?.message,
        },
        "E9yRYVsT59P_CtA-W"
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
        toast.success("Email send successfully");
        generateCaptcha();
        navigate('/welcome')
        setFormData({});

      })
      .catch((error) => {
        console.error("Email failed to send:", error);
      });
  };
  const formik = useFormik({
    initialValues: {
      companyName: "",
      email: "",
      websiteURL: "",
      phoneNumber: "",
      message: "",
      captcha: "",
    },
    validationSchema: validationSchema,
    onSubmit:sendEmail
    //  (values) => {
    //   // Your signup logic here
    //   console.log("Signup form submitted with values:", values);
    //   sendEmail
    //   generateCaptcha();
    //   navigate('/welcome')
    // },
  });

  const generateCaptcha = () => {
    const possibleChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * possibleChars.length);
      captcha += possibleChars.charAt(randomIndex);
    }
    // Set the generated captcha in the state
    setCaptchaText(captcha);
    // Return the generated captcha
    return captcha;
  };

  console.log(captchaText);
  useEffect(() => {
    generateCaptcha();
  }, []);
  const createDynamicSvg = (text) => `
<svg xmlns="http://www.w3.org/2000/svg" width="150" height="50" viewBox="0 0 150 50" fill="none">
  <text x="10" y="40" font-family="Arial" font-size="22" letter-spacing="6" fill="#333333">${text}</text>

<path d="M4 48C83 26 82 33 136 20" stroke="#777777" />
<path d="M4 22C61 20 65 39 134 36" stroke="#888888" />

<path d="M5 23C76 15 94 5 136 16" stroke="#444444" />
<path d="M2 16C61 45 57 15 142 28" stroke="#666666" />
</svg>
`;
  return (
    <div className="flex  w-screen h-screen overflow-hidden flex-wrap">
      <div className=" lg:w-1/2 w-full h-full lg:flex hidden ">
        <img src={sideleft} alt="signup page image of rapidogate" className="w-full h-full object-cover " />
      </div>
      <div className=" lg:w-1/2 w-full h-full  xl:pr-16  px-3">
        <div className=" md:w-[87%] w-[99%] my-4">
          <div className="flex justify-between">
            <Link
              to="/"
              className="text-[#757575] font-Popins text-[12px] font-normal flex items-center text-center"
            >
              <AiOutlineLeft className="text-[22px] mr-1" /> Return Home
            </Link>
            <p className="text-[#757575] font-Popins text-[12px] font-normal ">
              Already a Member?
              <Link
                to="/login"
                className=" ml-2 text-[#201064] font-bold underline "
              >
                LOG IN NOW
              </Link>
            </p>
          </div>
        </div>

        <div
          className="flex flex-col w-full mt-4  lg:h-[85%] h-[90%] xl:gap-3  gap-2 justify-center text-center
        items-center"
        >
          <h1 className="text-[#201064] xl:text-[25px] text-[20px] font-extrabold ">
            BECOME AN EXCLUSIVE MEMBERS
          </h1>
          <p className="text-[#424242] uppercase font-normal xl:text-[16px] text-[14px] ">
            Partner With Us Today!
          </p>
          <div className="lg:w-[68%] w-full my-6 ">
            <form
              className="w-full flex flex-col xl:gap-5 gap-4"
              onSubmit={formik.handleSubmit}
            >
              <div className="pb-2 xl:h-[27px] h-[26px] ">
                <input
                  type="text"
                  placeholder="Company Name"
                  name="companyName"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                  onBlur={formik.handleBlur}
                  value={formik.values.companyName}
                  className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 ${
                    formik.touched.companyName && formik.errors.companyName
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.companyName && formik.errors.companyName && (
                  <div className="text-red-500 text-[12px] text-left">
                    {formik.errors.companyName}
                  </div>
                )}
              </div>
              <div className="pb-2 xl:h-[27px] h-[26px] ">
                <input
                  type="email"
                  placeholder="Email"
                  name="email"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                  onBlur={formik.handleBlur}
                  value={formik.values.email}
                  className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 ${
                    formik.touched.email && formik.errors.email
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.email && formik.errors.email && (
                  <div className="text-red-500 text-[12px] text-left">
                    {formik.errors.email}
                  </div>
                )}
              </div>
              <div className="pb-2 xl:h-[27px] h-[26px] ">
                <input
                  type="text"
                  name="websiteURL"
                  placeholder="Website URL"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                  onBlur={formik.handleBlur}
                  value={formik.values.websiteURL}
                  className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 ${
                    formik.touched.websiteURL && formik.errors.websiteURL
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.websiteURL && formik.errors.websiteURL && (
                  <div className="text-red-500 text-[12px] text-left">
                    {formik.errors.websiteURL}
                  </div>
                )}
              </div>
              <div className="pb-2 xl:h-[27px] h-[26px] ">
                <input
                  type="text"
                  name="phoneNumber"
                  placeholder="Phone Number"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                  onBlur={formik.handleBlur}
                  value={formik.values.phoneNumber}
                  className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 ${
                    formik.touched.phoneNumber && formik.errors.phoneNumber
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.phoneNumber && formik.errors.phoneNumber && (
                  <div className="text-red-500 text-[12px] text-left">
                    {formik.errors.phoneNumber}
                  </div>
                )}
              </div>
              <div className="pb-2 xl:h-[27px] h-[26px] ">
                <input
                  type="text"
                  placeholder="Write your message.."
                  name="message"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                  onBlur={formik.handleBlur}
                  value={formik.values.message}
                  className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 ${
                    formik.touched.message && formik.errors.message
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.message && formik.errors.message && (
                  <div className="text-red-500 text-[12px] text-left">
                    {formik.errors.message}
                  </div>
                )}
              </div>

              <div className="flex gap-4 items-center">
                <span
                  dangerouslySetInnerHTML={{
                    __html: createDynamicSvg(captchaText),
                  }}
                />{" "}
                <span
                  className="cursor-pointer"
                  onClick={() => generateCaptcha()}
                >
                  {reload}
                </span>
              </div>

              <div className="pb-2 xl:h-[27px] h-[26px] ">
                <input
                  type="text"
                  placeholder="Captcha"
                  name="captcha"
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                  onBlur={formik.handleBlur}
                  value={formik.values.captcha}
                  className={`w-full border-b-2 border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 ${
                    formik.touched.captcha && formik.errors.captcha
                      ? "border-red-500"
                      : ""
                  }`}
                />
                {formik.touched.captcha && formik.errors.captcha && (
                  <div className="text-red-500 text-[12px] text-left">
                    {formik.errors.captcha}
                  </div>
                )}
              </div>

              <button
                className="bg-[#201064] xl:h-[58px] xl:text-[16px] text-[13px] h-[50px] text-white rounded-[5px] font-medium w-full mt-2 "
                onClick={formik.handleSubmit}
              >
                Sign Up
              </button>
            </form>
          </div>
        </div>
        <div className=" md:w-[90%] w-[99%] ">
          <div className="flex justify-between">
            <p className="text-[#757575] font-Popins text-[12.8px] font-normal flex items-center text-center">
              Copyright {date.getFullYear()} Rapidogate. All rights Reserved
            </p>
            <p className="text-[#757575] font-Popins text-[12px] font-normal  flex items-center text-center">
              <BsInfoLg className="text-[22px] mr-1" />{" "}
              <Link to="/faqs">Need help?</Link>
            </p>
          </div>
        </div>
      </div>
    </div>
  );
};

export default Signup;
