import React from "react";
import Intgrat from "../../assets/images/Integrateed.png";
import { Link } from "react-router-dom";

const Integrate = () => {
  const containerStyle = {
    width: "100vw",
    minHeight: window.innerWidth > 1600 ? "20.5vw" : "26.6vw", 
   // Adjust the value as needed
    backgroundImage: `url(${Intgrat})`,
    backgroundSize: "100% 100% ",
    backgroundRepeat: "no-repeat",
    backgroundPosition: "center",
    
  };

  return (
    <div className="relative overflow-hidden xl:pt-7  mb-10 bg_image" style={containerStyle}>
      <div className="h-full flex flex-col justify-center text-center items-center md:gap-4 gap-2 ">
        <span className="text-[#181066] uppercase font-bold xl:text-[14px] text-[12px] font-Gilroy">
          Rapidogate - Payment gateway
        </span>
        <h2 className="font-extrabold xl:text-[40px] md:text-[30px] text-[24px] px-[1.5rem] text-black">
        Simplify Payments with Rapidogate's<br className="hidden xl:block" /> QR Anywhere You Sell
        </h2>
        <p className="text-[#64607D] font-medium xl:text-[20px] tracking-[-0.4px]">
        Grow Your Profits and expand your business
        </p>
        <Link to="/signup" role="button" className="md:w-[178px] w-[90%] lg:text-[16px] text-[13px] md:h-[53px] h-[40px] flex justify-center items-center bg-[#181066] rounded-[45px] font-medium text-white">
        Sign Up Now
        </Link>
      </div>
    </div>
  );
};

export default Integrate;
