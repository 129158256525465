import React from "react";
import { useNavigate } from "react-router-dom";

const ChoseUs = () => {
  const navigate = useNavigate();
  return (
    <div className=" w-full  bg-theme_color  text-white md:py-20 py-10">
      <div className="containar flex md:flex-row flex-col xl:gap-32 gap-10 justify-center items-center w-full">
        <div className="md:w-[50%] w-full">
          <p className="uppercase font-bold md:text-[14px] text-[10px]">
            Why Choose Us
          </p>
          <h3 className="font-bold xl:text-[42px] md:text-[32px] text-[28px] tracking-tight xl:leading-[60px]">
            Join 200+ Businesses Excelling with Rapidogate's Dynamic QR Payments
          </h3>
          <p className="font-medium md:text-[20px]  tracking-[-0.4px] md:leading-[30px] mt-3">
            Sign up today and see why our QR code system is the preferred choice
            for smooth immediate customer payments.
          </p>
        </div>
        <div className="md:w-[45%] w-full">
          <input
            type="text"
            placeholder="Email Address"
            className="md:w-[85%] w-full text-[#BBB5E0]  bg-[#F2F3F4] bg-opacity-20 rounded md:h-[60px] h-[45px] px-3 outline-0 "
          />
          <input
            type="password"
            placeholder="Password"
            className="md:w-[85%] w-full text-[#BBB5E0]  bg-[#F2F3F4] bg-opacity-20 rounded md:h-[60px] h-[45px] px-3 outline-0 my-5 "
          />
          <button
            className="md:w-[85%] w-full lg:text-[16px] text-[13px] text-white  bg-[#05E0D5] md:h-[60px] h-[45px] outline-0 rounded font-bold  uppercase "
            onClick={() => navigate("/welcome")}
          >
            Join Now
          </button>
        </div>
      </div>
    </div>
  );
};

export default ChoseUs;
