import React from "react";
import ChoseUs from "../../component/home/ChoseUs";
import Integrate from "../../component/home/Integrate";
import Card from "../../component/home/Card";
import pic1 from "../../assets/images/pic1.png";
import pic2 from "../../assets/images/pic2.png";
import pic3 from "../../assets/images/pic3.png";
import pic4 from "../../assets/images/pic4.png";
import CenteredCarousel from "../../component/Crousal";
import Business from "../../component/home/Business";
import Work from "../../component/home/Work";
import Hero from "../../component/home/Hero";
import Header from "../../component/header";
import Footer from "../../component/footer";
import { Link, useNavigate } from "react-router-dom";

const Home = () => {
  const data = [
    {
      id: 1,
      tittle: "Receive Payment Via Website",
      des: "0% transaction charges. Increase stability of your business by reducing amount of unnecessary charges",
      link: "#",
      image: pic2,
    },
    {
      id: 2,
      tittle: "Accepts payments via mobile apps",
      des: "Accept payments anywhere customers are – websites, Apps, stores etc.",
      link: "#",
      image: pic1,
    },
    {
      id: 3,
      tittle: "Easily collect payments in just one click",
      des: "Instant payout to your account . Receive payments directly to your bank account for better cashflow",
      link: "#",
      image: pic3,
    },
    {
      id: 1,
      tittle: "Real-Time Data Analysis with Interactive Dashboards",
      des: "Interactive Dashboard. Make data driven decisions with interactive dashboard that allow you to monitor transactions in real-time and analyze performance on the go.",
      link: "#",
      image: pic4,
    },
  ];
  const navigate = useNavigate();
  return (
    <>
      <Header />
      <Hero />
      <Work />
      <Business />
      <div className="py-10">
        <CenteredCarousel />
      </div>
      <Integrate />
      <div className=" containar flex flex-wrap gap-12 md:mb-20 mb-4 justify-center xl:px-48 ">
        {data.map((item, index) => (
          <Card key={index} item={item} />
        ))}
        <button
          className="px-[2.7em] lg:text-[16px] md:text-[13px] md:w-auto w-full text-[11px] py-[0.6rem] bg-[#181066] rounded-full font-semibold text-white"
          onClick={() => navigate("/login")}
        >
          Start Integration{" "}
        </button>
      </div>

      <ChoseUs />
      <Footer />
    </>
  );
};

export default Home;
