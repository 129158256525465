import React, { useEffect, useState } from "react";
import Header from "../../component/header";
import { recapcha, reload } from "../../assets/svgs";
import Image from "../../assets/images/rafiki.png";
import axios from "axios";
import { useFormik } from "formik";
import * as Yup from "yup";
import { toast } from "react-toastify";
import emailjs from "emailjs-com";

const ContactUs = () => {
  const [formData, setFormData] = useState({});
  const [captchaText, setCaptchaText] = useState();
  const validationSchema = Yup.object().shape({
    NAME: Yup.string().required("Company Name is required"),
    EMAIL: Yup.string()
      .email("Invalid email address")
      .required("Email is required"),
    Website: Yup.string().required("Website URL is required"),
    PHONE: Yup.string().required("Phone Number is required"),
    COMMENTS: Yup.string().required("Message is required"),
    recaptch: Yup.string().required("Captcha is required"),
  });

  const generateCaptcha = () => {
    const possibleChars =
      "ABCDEFGHIJKLMNOPQRSTUVWXYZabcdefghijklmnopqrstuvwxyz0123456789";
    let captcha = "";
    for (let i = 0; i < 6; i++) {
      const randomIndex = Math.floor(Math.random() * possibleChars.length);
      captcha += possibleChars.charAt(randomIndex);
    }
    // Set the generated captcha in the state
    setCaptchaText(captcha);
    // Return the generated captcha
    return captcha;
  };

  console.log(captchaText);
  useEffect(() => {
    generateCaptcha();
  }, []);

  const handleInputChange = (e) => {
    setFormData({
      ...formData,
      [e.target.name]: e.target.value,
    });
  };
  const createDynamicSvg = (text) => `
    <svg xmlns="http://www.w3.org/2000/svg" width="150" height="50" viewBox="0 0 150 50" fill="none">
      <text x="10" y="40" font-family="Arial" font-size="22" letter-spacing="6" fill="#333333">${text}</text>
    
    <path d="M4 48C83 26 82 33 136 20" stroke="#777777" />
    <path d="M4 22C61 20 65 39 134 36" stroke="#888888" />
   
    <path d="M5 23C76 15 94 5 136 16" stroke="#444444" />
    <path d="M2 16C61 45 57 15 142 28" stroke="#666666" />
    </svg>
  `;
  const sendEmail = () => {
    emailjs
      .send(
        "service_446oasn",
        "template_u8sau2h",
        {
          to_name: formData?.NAME,
          email: formData?.EMAIL,
          website: formData?.Website,
          phone: formData?.PHONE,
          message: formData?.COMMENTS,
        },
        "E9yRYVsT59P_CtA-W"
      )
      .then((response) => {
        console.log("Email sent successfully:", response);
        toast.success("Email send successfully");
        setFormData({});

      })
      .catch((error) => {
        console.error("Email failed to send:", error);
      });
  };

  // Function to be called after the Bitrix24 form is submitted
  const submitToBitrix24Form = async () => {
   console.log(formData)
    if (formData?.recaptch?.toLowerCase() === captchaText.toLowerCase()) {
      // User entered correct captcha, perform your form submission logic here
      console.log("Form submitted successfully!");

      // Generate a new captcha for the next submission
      setCaptchaText(generateCaptcha());
      const newData = {
        ...formData,
        PHONE: [{ VALUE: formData.PHONE, VALUE_TYPE: "WORK" }],
        EMAIL: [
          {
            ID: "",
            VALUE_TYPE: "OTHER",
            VALUE: formData.EMAIL,
            TYPE_ID: "EMAIL",
          },
        ],
      };

      try {
        // const response = await axios.post(
        //   "https://rapidofin.bitrix24.com/rest/1/j3opfwgwfkq9pe00//crm.contact.add",
        //   {
        //     fields: {
        //       ...newData,
        //       UF_CRM_5C2CE5933F0E6: 8, // Set the form ID as a custom field
        //     },
        //   }
        // );

        // toast.success("Your contact added successfully");
        sendEmail();
        formik.resetForm();
        setFormData({});
        // console.log("Bitrix24 form submission successful:", response.data);
      } catch (error) {
        console.error("Error submitting to Bitrix24 form:", error.message);
      }
    } else {
      toast.error("please enter a valid recaptcha");
    }
  };
  const formik = useFormik({
    initialValues: {
      NAME: "",
      EMAIL: "",
      Website: "",
      PHONE: "",
      COMMENTS: "",
      recaptch: "",
    },
    validationSchema: validationSchema,
    onSubmit: submitToBitrix24Form, // Your form submission logic
  });

  return (
    <>
      <Header />
      <div className="flex flex-col justify-center text-center items-center gap-2 font-Popins not-italic containar">
        <div className="xl:w-[80%] lg:w-[90%] w-full flex lg:flex-row flex-col  border-2 rounded-[10px] border-[#D1D1D8] p-2 my-4">
          <div className="lg:p-10 lg:w-1/2 w-full text-left items-start  rounded-lg">
            <div className="lg:flex flex-col text-center justify-center">
              <h1 className="text-[#201064] text-[32px] font-extrabold ">
                Contact Us
              </h1>
              <p className="text-[#374151] text-[14px] font-normal  ">
                Have any questions? We'd love to hear from you. <br />
                office@rapidogate.com
              </p>
            </div>
            <div className="image_container  lg:flex hidden">
              <img
                src={Image}
                alt="contact page image of rapidogate"
                className="w-full h-full object-cover"
              />
            </div>
          </div>
          <div className="lg:w-1/2 w-full text-left items-start ">
            <form
              data-b24-form="inline/8/3w4w4f"
              data-skip-moving="true"
              onSubmit={formik.handleSubmit}
            >
              <div className="w-full flex flex-col gap-2 text-[#000] font-medium px-7 py-3 ">
                <div>
                  <label className="w-full text-[12px]  pb-2 text-[#8D8D8D] ">
                    Company Name
                  </label>
                  <input
                    type="text"
                    placeholder="Dio"
                    name="NAME"
                    value={formik.values.NAME}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className={`w-full text-[14px] border-b-2 placeholder-text-[#8D8D8D] border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pb-2 md:h-[29px] h-[22px] ${
                      formik.touched.NAME && formik.errors.NAME
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.NAME && formik.errors.NAME && (
                    <div className="text-red-500 text-[12px]">
                      {formik.errors.NAME}
                    </div>
                  )}
              
                </div>
                <div>
                  <label className="w-full text-[12px]  text-[#8D8D8D] pb-2 ">
                    Email
                  </label>

                  <input
                    type="email"
                    name="EMAIL"
                    placeholder="dio@gmail.com"
                    value={formik.values.EMAIL}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className={`w-full text-[14px] border-b-2 placeholder-text-[#8D8D8D] border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pb-2 md:h-[29px] h-[22px] ${
                      formik.touched.EMAIL && formik.errors.EMAIL
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.EMAIL && formik.errors.EMAIL && (
                    <div className="text-red-500 text-[12px]">
                      {formik.errors.EMAIL}
                    </div>
                  )}
                </div>
                <div>
                  <label className="w-full text-[12px]  text-[#8D8D8D] pb-2 ">
                    Website URL
                  </label>

                  <input
                    type="text"
                    placeholder="jackenterprise.com"
                    name="Website"
                    value={formik.values.Website}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className={`w-full text-[14px] border-b-2 placeholder-text-[#8D8D8D] border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pb-2 md:h-[29px] h-[22px] ${
                      formik.touched.Website && formik.errors.Website
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.Website && formik.errors.Website && (
                    <div className="text-red-500 text-[12px]">
                      {formik.errors.Website}
                    </div>
                  )}
                </div>
                <div>
                  <label className="w-full text-[12px] text-[#8D8D8D]  pb-2 ">
                    Phone Number
                  </label>

                  <input
                    type="text"
                    name="PHONE"
                    placeholder="+1 012 3456 789"
                    value={formik.values.PHONE}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className={`w-full text-[14px] border-b-2 placeholder-text-[#8D8D8D] border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pb-2 md:h-[29px] h-[22px] ${
                      formik.touched.PHONE && formik.errors.PHONE
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.PHONE && formik.errors.PHONE && (
                    <div className="text-red-500 text-[12px]">
                      {formik.errors.PHONE}
                    </div>
                  )}
                </div>
                <div>
                  <label className="w-full text-[12px]  pb-2 text-[#8D8D8D]">
                    Message
                  </label>

                  <input
                    type="text"
                    name="COMMENTS"
                    placeholder="Write your message.."
                    value={formik.values.COMMENTS}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className={`w-full text-[14px] border-b-2 placeholder-text-[#8D8D8D] border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pb-2 md:h-[29px] h-[22px] ${
                      formik.touched.COMMENTS && formik.errors.COMMENTS
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.COMMENTS && formik.errors.COMMENTS && (
                    <div className="text-red-500 text-[12px]">
                      {formik.errors.COMMENTS}
                    </div>
                  )}
                </div>
                <div className="flex gap-4 items-center">
                  <span
                    dangerouslySetInnerHTML={{
                      __html: createDynamicSvg(captchaText),
                    }}
                  />{" "}
                  <span
                    className="cursor-pointer"
                    onClick={() => generateCaptcha()}
                  >
                    {reload}
                  </span>
                </div>
                <div>
                  <label className="w-full text-[12px]  pb-2 text-[#8D8D8D] ">
                    Captcha
                  </label>
                  <input
                    type="text"
                    name="recaptch"
                    placeholder=""
                    value={formik.values.recaptch}
                    onChange={(e) => {
                      formik.handleChange(e);
                      handleInputChange(e);
                    }}
                    onBlur={formik.handleBlur}
                    className={`w-full text-[14px] border-b-2 placeholder-text-[#8D8D8D] border-[#8D8D8D] focus:border-b-2 focus:border-green-600 outline-0 pb-2 md:h-[29px] h-[22px] ${
                      formik.touched.recaptch && formik.errors.recaptch
                        ? "border-red-500"
                        : ""
                    }`}
                  />
                  {formik.touched.recaptch && formik.errors.recaptch && (
                    <div className="text-red-500 text-[12px]">
                      {formik.errors.recaptch}
                    </div>
                  )}
                </div>
                <button
                  className="bg-[#201064] lg:text-[16px] text-[13px] lg:h-[64px] h-[50px] text-white rounded-[5px] mt-3 font-medium"
                  type="submit"
                >
                  Send Message
                </button>
              </div>
            </form>
          </div>
        </div>
      </div>
    </>
  );
};

export default ContactUs;
