import React from "react";
import Header from "../../component/header";
import welcome from "../../assets/images/welcome.png";
import Footer from "../../component/footer";

const Welcome = () => {
  return (
    <>
      <Header />
      <div className="containar">
      <div className=" flex flex-col justify-center text-center items-center gap-6 md:h-[85vh] h-[89vh] border-b-2 border-solid ">
        <h1 className="text-[#201064] xl:text-[32px] text-[24px] font-extrabold sm:text-2xl md:text-3xl lg:text-4xl">
          Welcome to Rapidogate! <br className="hidden md:block" />
          Your smart payment solution
        </h1>
        <p className="text-[#374151] text-[14px] font-normal px-7 sm:text-sm md:text-base lg:text-lg">
          Our team will reach out soon to help with any questions or support you
          need.
        </p>
        <img src={welcome} alt="welcome page image of rapidogate" className="max-w-full md:h-[50vh]" />
      </div>
      </div>
      <Footer />
    </>
  );
};

export default Welcome;

