import React, { useState } from "react";
import Slider from "react-slick";
import "slick-carousel/slick/slick.css";
import "slick-carousel/slick/slick-theme.css";
import { AiFillStar } from "react-icons/ai";
import girl from "../assets/images/girl.svg";
import santa from "../assets/images/anta.png";
import dev from "../assets/images/dev.png";
import anita from "../assets/images/anita.jpeg"

import { quote } from "../assets/svgs";
const CenteredCarousel = () => {
  const [centerItemIndex, setCenterItemIndex] = useState(0);

  const settings = {
    centerMode: true,
    infinite: true,
    autoplay: true,
    slidesToScroll: 1,
    centerPadding: "0",
    slidesToShow: 3,
    speed: 500,
    focusOnSelect: true,
    responsive: [
      {
        breakpoint: 1240,
        settings: {
          slidesToShow: 2,
        }
      },
      {
        breakpoint: 724,
        settings: {
          slidesToShow: 1,
        }
      },
    
      {
        breakpoint: 480,
        settings: {
          slidesToShow: 1,
        }
      },
    ],
    beforeChange: (current, next) => {
      setCenterItemIndex(next);
    },
    
  };
  const data = [
    {
      id: 1,
      name: "Anita R.",
      gread:"Craft Shop Owner",
      image: anita,
      cmt: "Each sale is easier now with Rapidogate. Customers just scan and go, and we don't worry about mistakes. It's great!",
    },
    {
        id: 2,
        name: "Chris L.",
        gread:"Craft Shop Owner`",
        image: santa,
        cmt: "Each sale is easier now with Rapidogate. Customers just scan and go, and we don't worry about mistakes. It's great!  ",
      },
      {
        id: 4,
        name: "Dave M.",
        gread:"Hardware Store Proprietor",
        image: dev,
        cmt: "No fees means we keep all our earnings. Rapidogate's QR codes make getting paid straightforward and quick. ",
      },
      {
        id: 3,
        name: "Ella K.",
        gread:"Independent Book Seller",
        image: girl,
        cmt: "Setting up Rapidogate was simple. Our customers use the QR code, pay fast, and we see our money right away. ",
      },
  ];

  return (
    <>
        <div className="flex flex-col justify-center text-center containar">
        <span className="font-bold text-[14px]  text-[#3734A9]">Testimonials</span>
            <h1 className="font-extrabold md:text-[40px] text-[24px] tracking-tight lg:leading-[48px] mb-5">Hear from Our Satisfied Business Partners</h1>
        </div>


    <div className="centered-carousel-container containar md:my-20 my-5">
    
      <Slider {...settings}>
        {data.map((item ,index) => (
          <div
            key={index}
            className={`carousel-item ${
              index === centerItemIndex ? "slick-center" : "not_centered"
            }   `}
          >
            <div className="mx-5 p-5 pb-0 ">
              <div className="flex gap-3">
                <span>
                  <AiFillStar color="#F52" />
                </span>
                <span>
                  <AiFillStar color="#F52" />
                </span>
                <span>
                  <AiFillStar color="#F52" />
                </span>
                <span>
                  <AiFillStar color="#F52" />
                </span>
                <span>
                  <AiFillStar color="#F52" />
                </span>
              </div>
              <p className="text-[#0C1F26] md:text-[18px] md:min-h-[200px] min-h-[150px] font-[500] my-3 leading-[150%]">
               {item.cmt}
              </p>
              <div className="flex gap-8 items-center md:mb-12 mb-6 border-t-2 pt-6 relative crosal_iamge">
                <span className="absolute md:top-[-28%] top-[-35%] right-24">{quote}</span>
                <div className=" md:w-[60px] w-[45px] md:h-[60px] h-[45px] ">
                  <img src={item.image} alt={` crousal image of ${item?.name}`} />
                </div>
                <div >
                  <h2 className="uppercase md:text-[20px] tracking-tight font-bold leading-[30px]">
                    {item.name}
                  </h2>
                  <p className="text-[#5C6666] font-normal leading-[150%] md:text-[16px] text-[13px]"> {item.gread}</p>
                </div>
              </div>
            </div>
          </div>
          
        ))}
      </Slider>
    </div>
    </>
  );
};

export default CenteredCarousel;
