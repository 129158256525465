import React from "react";
import {twitter } from "../../assets/svgs";
import { Link, NavLink } from "react-router-dom";
import logo from "../../assets/images/Logo.svg";

import { FaFacebookF, FaPinterestP } from "react-icons/fa";
const Footer = () => {
  return (
    <>
      <footer>
        <div className="pt-5 ">
          <div className="flex flex-wrap w-full justify-between   md:pb-6 pb-4">
            <div className="tracking-tight text-left flex flex-col  md:w-[30%] w-full">
              <NavLink to="/">
                <img src={logo} alt="logo of the RapidoGate" className="logo" />
              </NavLink>
              <p className="font-medium text-[#757095] md:text-[16px] text-[13px] ">
              Accept payments from your customers through our easy-to-use Dynamic QR code service, with 0% transaction fees.
              </p>
              {/* <div className="md:flex hidden gap-4 my-3 justify-start text-center items-center">
                <span>{linkdin}</span>
                <span>{messenger}</span>
                <span>{twitter}</span>
                <span>{twoo}</span>
              </div> */}
            </div>
            <div className="tracking-tight flex flex-col md:gap-3 gap-2 md:w-[16%]  md:text-left font-medium md:pl-10">
              <p className="text-[21px]  font-bold  text-text_color ">
                Company
              </p>
              <div className="flex md:flex-col flex-row flex-wrap md:gap-3">
                <Link to="/" className="md:w-auto w-1/2">
                  Home
                </Link>
                <Link to="/about" className="md:w-auto w-1/2">
                  About Company
                </Link>
                <Link to="/faqs" className="md:w-auto w-1/2">
                  FAQs
                </Link>
                <Link to="/contactus" className="md:w-auto w-1/2">
                  Contact Us
                </Link>
              </div>
            </div>
            <div className="tracking-tight text-left flex flex-col md:gap-4 md:w-[32%]  ">
              <p className="text-[21px]  font-bold  text-text_color ">
                Contact Us
              </p>

              <p className="font-medium text=[#181433] opacity-50  md:text-[16px] text-[13px]	">
                Feelfree to get in touch with us via send us a message
                support@rapidogate.com
              </p>
              <div className="flex gap-4 md:my-2 my-3 justify-start text-center items-center">
                <a href="https://www.pinterest.com/RapidoGate/" target="_blank">
                <FaPinterestP color="#3734A9" size={20}/>
                </a>
                <a href="https://www.facebook.com/Rapidogate/" target="_blank">
                  <FaFacebookF color="#3734A9" size={20}/>
                </a>
                <a href="https://twitter.com/RapidoGate" target="_blank">
                  <span>{twitter}</span>
                </a>
             
              </div>
            </div>
          </div>
        </div>
      </footer>
      <div className="flex flex-wrap w-full justify-between font-medium  md:px-[6rem] px-[1.5rem] md:text-[14px] text-[13px] bg-theme_color text-white py-5">
        <p>Copyright @ Rapidogate. All Rights Reserved.</p>
        <p className="md:mt-0 mt-2">
          Refund Policy <span className=" mx-2">| </span> Terms Of Service
        </p>
      </div>
    </>
  );
};

export default Footer;
