import React, { useState } from "react";
import Header from "../../component/header";
import FaqImage from "../../assets/images/FAQs.png";
import Faq from "react-faq-component";
const Faqs = () => {
    const [openItemIndex, setOpenItemIndex] = useState(0);
  const data = {
    title: "",
    rows: [
      {
        title: "1. What is Rapidogate?",
        content: `Rapidogate provides dynamic QR codes for businesses, making payment collection direct and without deductions. Whether you have a clothing store or a food portal. With Rapidogate you can have a seamless experience.`,
      },
      {
        title: "2. How can I start with Rapidogate?",
        content:
          "Sign up on our website, and you'll be guided through a simple process to integrate our QR service into your system.",
      },
      {
        title: "3. What payment methods Rapidogate QR support?",
        content: `Our QR codes accept payments from all UPI apps and bank accounts, including popular ones like Paytm, Apple Pay and Google Pay. `,
      },
      {
        title: "4. Is Rapidogate secure?",
        content: `Absolutely. We prioritize your security, using top-notch measures to keep every transaction safe.`,
      },
      {
        title:
          "5. How does Rapidogate operate?",
        content: `Rapidogate generates a unique QR code at checkout, which customers can scan to pay through any UPI app without fees. We verify payments and send confirmation swiftly.`,
      },
      {
        title: "6. Who can use Rapidogate?",
        content: `Rapidogate is ideal for any business, big or small, looking to simplify their payment process.`,
      },
      {
        title: "7. What does Rapidogate cost?",
        content: `You can log in and check the 'Buy Plan' section for detailed pricing information.`,
      },
    ],
  };

  const config = {
    animate: true,
    arrowIcon: "",
    openOnload: 0,
    expandIcon: "+",
    collapseIcon: "-",
  };
  const styles = {
    rowTitleColor: "#000",
    rowContentColor: "#374151",
    rowTitleTextSize: window.innerWidth>780? "22px" : "16px",

    rowContentTextSize: window.innerWidth>780? "16px" : "12px",
    rowContentPaddingTop: "10px",
    rowContentPaddingRight: "60px",
    rowContentPaddingBottom: "10px",
    rowContentPaddingLeft: "10px",
  };
  return (
    <>
      <Header />
      <div className="flex  containar mt-10 w-full flex-wrap ">
        <div className="lg:w-1/2 w-full">
          <img src={FaqImage} alt="faqs page image of rapidogate" className="w-full" />
        </div>
        <div className="lg:w-1/2 w-full flex flex-col justify-center items-center pb-10 ">
          <div className="faq-container flex-grow">
            <Faq
              data={data}
              styles={styles}
              config={config}
              expandedIndex={openItemIndex}
              onToggle={(index) => setOpenItemIndex(index)}
            />
          </div>
        </div>
      </div>
    </>
  );
};

export default Faqs;
