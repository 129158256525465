import React from "react";
import Header from "../../component/header";
import Footer from "../../component/footer";
import image from "../../assets/images/aboutpic.png"

const About = () => {
  return (
    <>
      <Header />
      <div className=" containar">
      <div className="flex lg:flex-row flex-col gap-2 not-italic py-6 border-b-2 border-solid">
        <div className="lg:w-[50%] w-full  ">
          <div className="border-b-2 border-solid pb-4">
            <h1 className="xl:text-[32px] text-[22px] text-theme_color font-extrabold tracking-tight xl:leading-[44px] leading-7 ">
              About Company
            </h1>
            <p className="my-3 text-[#374151] md:text-[15px] text-[11px] leading-[24px]">
              Digital Payments is a key fintech business opportunity that
              remains underserved. Rapidogate offers the complete range of
              Payment Tech that can help you build a profitable and scalable
              payment business to lead the market.
            </p>
          </div>
          <p className="my-4 text-[#374151] md:text-[15px] text-[11px] leading-[24px]">
          Modern customers have the patience neither for lengthy check-outs nor for waiting. Payments using QR codes are faster as compared to other modes. Typically, users only need to open the necessary app, scan the QR code, and confirm the payment. This way, QR codes placed outside a restaurant or street food stall, for example, solve the problem of queues because customers don’t need to wait to order and pay for their food.
          </p>
          <p className="my-3 text-[#374151] md:text-[15px] text-[11px] leading-[24px]">
          High internet speeds allow for nearly instant payment processing, so it takes 1-3 seconds on average to complete payment.
            </p>
            <p className="my-3 text-[#374151] md:text-[15px] text-[11px] leading-[24px]">
            Without queues, interpretation, fumbling around for change, etc., the entire process of serving customers can become more efficient. This helps speed up sales, increase the number of customers a business can serve, and focus on providing the best customer experience.
            </p>
            <p className="my-3 text-[#374151] md:text-[15px] text-[11px] leading-[24px]">
            Rapidogate platform has evolved over time to cater to the specific needs of the growth markets.
            </p>
            <p className="my-3 text-[#374151] md:text-[15px] text-[11px] leading-[24px]">
            We offer complete flexibility and easy integration and keep robust compliance with the needs of conventional UPI, Net Banking and Bank card payments.
            </p>
        </div>
        <div className="lg:w-[48%] w-full flex justify-center text-center items-center ">

            <img src={image} alt="about image of rapidogate" className="h-[390px]" />
        </div>
      </div>
      </div>
      <Footer />
    </>
  );
};

export default About;
