import React from "react";
import { Link } from "react-router-dom";

const Card = ({item}) => {
 
  return (
    <div
      className="w-full md:w-[47%] rounded-[6.618px] border-solid border-[#E0E0E0] border-[0.827px] bg-white"
      style={{
        boxShadow:
          "0px 24.81623px 37.22435px -24.81623px rgba(50, 50, 93, 0.25), 0px 14.88974px 29.77948px -14.88974px rgba(0, 0, 0, 0.10)",
      }}
    >
      <div className="w-full md:h-[308px] h-[200px] bg-[#F0F4F8] pt-6 rounded-[6.618px]">
      <img
    src={item?.image}
    alt={`card detail image  of ${item.tittle}`}
    className="w-full h-full object-contain transition-transform  transform hover:scale-[1.14] pb-4 "
  />
      </div>
      <div className="md:pl-14 pl-5 py-4 flex flex-col gap-5 xl:mr-28 ">
        <h2 className="font-Gilroy font-medium md:text-[20px]">{item.tittle}</h2>
        <p className="text-[#374151] font-DM Sans"> {item.des}</p>
        {/* <Link to={"/login"} className="font-Gilroy font-medium md:text-[20px] text-[#181066]">Integrate Now {" "} {" >"}</Link> */}
      </div>
    </div>
  );
};

export default Card;
