import { BrowserRouter, Route, Routes } from 'react-router-dom';
import Home from './pages/home';

import Login from './pages/login';
import Signup from './pages/signup';
import Welcome from './pages/welcome';
import ContactUs from './pages/contact';
import Faqs from './pages/faqs';
import About from './pages/about';
import 'react-toastify/dist/ReactToastify.css';
import { ToastContainer } from 'react-toastify';


function App() {
  return (
    <BrowserRouter >
    <ToastContainer
position="top-center"
autoClose={5000}
hideProgressBar={false}
newestOnTop={false}
closeOnClick
rtl={false}
pauseOnFocusLoss
draggable
pauseOnHover
theme="light"
/>
    <Routes>
    
      <Route path="/" element={<Home />} /> 
      <Route path="/login" element={<Login />} /> 
      <Route path="/signup" element={<Signup />} /> 
      <Route path="/welcome" element={<Welcome />} /> 
      <Route path="/contactus" element={<ContactUs />} />
      <Route path="/faqs" element={<Faqs />} /> 
      <Route path="/about" element={<About />} /> 
      <Route path="*" element={<Home />} /> 
      
    </Routes>
    
  </BrowserRouter>
  );
}

export default App;
